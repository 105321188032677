<template>
<div>
  <div class="search-box">
    <el-form :inline="true" :model="queryForm" class="demo-form-inline">
          <el-form-item label="分组">
            <el-input
              v-model="queryForm.groupName"
              placeholder="请输入分组"
              size="small"
              clearable
              @clear="clearQueryInput"
            ></el-input>
          </el-form-item>

          <el-form-item label="状态">
            <el-select
              v-model="queryForm.status"
              style="width: 100%;"
              size="small"
              clearable
              @clear="queryRotation"
              @change="queryRotation"
              placeholder="请选择状态">
                <el-option :value="1" label="展示"></el-option>
                <el-option :value="0" label="未展示"></el-option>
          </el-select>
           
          </el-form-item>

      <el-form-item>
        <el-button icon="el-icon-search" type="primary" @click="submit" size="small">查询</el-button>
        <el-button v-show="hasAddPermision" icon="el-icon-plus" @click="addInfo" size="small"
        >新增
        </el-button
        >
      </el-form-item>
    </el-form>
  </div>
  <div class="data-box">
    <el-table
        v-loading="loading"
        element-loading-text="加载中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        :data="tableData"
        style="width: 100%">
          <el-table-column prop="groupName" label="分组"/>

          <el-table-column prop="image" label="图片">
            <template #default="scope">    
            <div style="width:100px; height:40px;">
              <img v-if="scope.row.image!='' && scope.row.image!=null" :src="serverUrl+''+scope.row.image" style="width: 100%;height: 100%;" 
                           @click="showImage(scope.row)" />
            </div>        
                    
            </template>         
          </el-table-column>
      <el-table-column prop="url" label="地址" align="center">
        <template #default="scope">
          <el-link @click="openLink(scope.row.url)">{{ scope.row.url }}</el-link>
        </template>
      </el-table-column>
      <el-table-column
          prop="clicks"
          label="浏览量"
      />
          <el-table-column prop="seq" label="排序"/>
          <el-table-column prop="status" label="状态">
            <template #default="scope"> 
                <el-tag v-if="scope.row.status==1">展示</el-tag>
                <el-tag type="info" v-else>未展示</el-tag>
            </template>
          </el-table-column>
      <el-table-column label="操作" fixed="right" width="180" v-if="hasEditPermision || hasDeletePermision">
        <template #default="scope">
          <el-button v-show="hasEditPermision" icon="el-icon-edit" size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              :title="'确定删除轮播图信息表['+scope.row.id+']吗?'"
              @confirm="handleDelete(scope.$index, scope.row)"
          >
            <template #reference>
              <el-button v-show="hasDeletePermision" icon="el-icon-delete" size="mini" type="danger">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <div class="page-box">
    <el-pagination
        v-model:currentPage="curPage"
        :page-sizes="[15, 50, 100, 200]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background="true"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>

  <!-- 图片预览 -->
  <el-dialog title="图片预览" width="1200px" v-model="dialogRoation" :show-close="false">
    <div class="preview">
      <img :src="dialogRoationUrl" alt="">
    </div>
  </el-dialog>


  <!--轮播图信息表新增、修改弹窗-->
  <el-dialog
      v-model="dialogVisible"
      :title="dialogTitle"
      width="30%"
      @close="handleClose"
  >
    <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="80px"
        class="demo-ruleForm"
    >
            <el-form-item label="分组" prop="groupName" class="label-color">
                <el-input
                        v-model="ruleForm.groupName"
                        placeholder="请输入分组"
                        clearable
                ></el-input>
            </el-form-item>
<!--            <el-form-item label="标题" prop="title" class="label-color">
                <el-input
                        v-model="ruleForm.title"
                        placeholder="请输入标题"
                        clearable
                ></el-input>
            </el-form-item>-->
            <el-form-item label="链接" class="label-color">
                <el-input
                        v-model="ruleForm.url"
                        placeholder="请输入链接"
                        clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="图片" prop="image" class="label-color">
               <el-upload
                    class="avatar-uploader"
                    :action="thumbnailAction"
                     :on-change="uploadImage"
                    :show-file-list="false"
                    :before-upload="beforeThumbnailUpload">
                    <img v-if="ruleForm.image!=null && ruleForm.image!=''" :src="ruleForm.image" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  
                  </el-upload>
              <el-link :underline="false" type="primary">(宽高比3:1为最佳显示效果)</el-link>
            </el-form-item>

            <el-row>
              <el-col :span="12">
                 <el-form-item label="排序" class="label-color">
                    <el-input-number v-model="ruleForm.seq" @change="handleChange" :min="1" label="描述文字"></el-input-number>
                  </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="状态" prop="status" class="label-color">
                  <el-select
                  v-model="ruleForm.status"
                  style="width: 100%"
                  class="m-2"
                  clearable
                  placeholder="请选择状态"
                  size="large">
                    <el-option :value="1" label="展示"></el-option>
                    <el-option :value="0" label="未展示"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveInfo" v-if="isSave == true"
        >保存</el-button
        >
        <el-button type="primary" @click="editInfo" v-if="isSave == false"
        >修改</el-button
        >
      </span>
    </template>
  </el-dialog>
  </div>
</template>

<script>
import rotationApi from "@/api/info/rotation";
import {ref} from "vue";
import config from '@/api/config'
import imageUtil from '@/utils/imageUtil'
import requestUtil from '@/utils/requestUtil';
export default {
  data() {
    return {
      hasAddPermision:false,
      hasEditPermision:false,
      hasDeletePermision:false,
      queryForm: {
        title: "",
        status: '',
        pageSize: 15,
        curPage: 1,
      },
      loading: false,
      total: 0,
      tableData: [],
      isSave: false,
      ruleForm: {
        id: "",
        title: "",
        url: "",
        image: "",
        seq: 1,
        status: "",
      },
      rules: {
        groupName: [{required: true, message: "请输入分组", trigger: ["blur", "change"],},],
        image: [{required: true, message: "上传轮播图", trigger: ["blur", "change"],},],
        status: [{required: true, message: "请选择状态", trigger: ["blur", "change"],},],
      },
      dialogTitle: "",
      dialogVisible: false,
      dialogRoation: false,
      dialogRoationUrl: false,
      serverUrl:'',
    };
  },
  mounted() {
    this.$nextTick(()=> {
      // 获取设置按钮权限
      this.hasAddPermision = this.$menuUtil.hasMenu('/info/rotationManage:add');
      this.hasEditPermision = this.$menuUtil.hasMenu('/info/rotationManage:edit');
      this.hasDeletePermision = this.$menuUtil.hasMenu('/info/rotationManage:delete');
    })
    this.queryRotation();
    this.serverUrl = config.server;
  },
methods: {
// 新标签打开链接
  openLink(url){
    if(url&&url!=''){
      window.open(url)
    }
  },
  showImage(row){
    this.dialogRoationUrl = this.serverUrl + row.image;
    this.dialogRoation = true;
  },

  //上传图片转base64
  uploadImage(file, fileList){
      imageUtil.getBase64(file.raw).then(res =>{
        this.ruleForm.image = res;
      })
    },    

  beforeThumbnailUpload(file) {
    let types = ['image/jpeg', 'image/jpg', 'image/png'];
    const isJPG = types.includes(file.type);
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isJPG) {
      this.$message.error('上传图片只能是 JPEG、JPG、PNG 格式!');
    }
    if (!isLt2M) {
      this.$message.error('上传图片大小不能超过 2MB!');
    }
    return isJPG && isLt2M;
  },

  clearQueryInput(){
    this.queryRotation();
  },
  submit() {
    console.log(this.queryForm)
    this.queryForm.curPage = 1;
    this.queryRotation();
  },
  handleSizeChange(val) {
    this.queryForm.pageSize = val;
    this.queryRotation();
  },
  handleCurrentChange(val) {
    this.queryForm.curPage = val;
    this.queryRotation();
  },
  queryRotation() {
     rotationApi.queryRotation(this.queryForm)
        .then((response) => {
          this.tableData = response.data.data.records;
          this.total = response.data.data.total;
        });
  },
  handleEdit(index, row) {
    console.log(index, row);
    //深拷贝,否则表单中改变值table也会改变
    let tmpRow = Object.assign({},row);    
    this.isSave = false;
    this.dialogTitle = "编辑轮播图";
    this.ruleForm=tmpRow;
    this.ruleForm.image = this.serverUrl + tmpRow.image;
    this.dialogVisible = true;
  },
  addInfo() {
    this.isSave = true;
    this.dialogTitle = "新增轮播图";
    this.dialogVisible = true;
    // 重置表单
    this.ruleForm = {
      seq:1,
      status: 1,
    };
    
  },
  handleDelete(index, row) {
     rotationApi.delRotation(row.id).then((response) => {
      if (response.data.code == 1) {
        this.$notify({
          title: response.data.msg,
          type: "success",
          offset: 40,
          duration: 2000,
        });
        this.tableData.splice(index, 1);
      } else {
        this.$notify.info({
          title: response.data.msg,
          type: "warning",
          offset: 40,
          duration: 3000,
        });
      }
    });
  },
  saveInfo() {
    this.$refs["ruleForm"].validate((valid) => {
      if (valid) {
        // 调用接口保存
         rotationApi.addRotation(this.ruleForm).then((response) => {
          if (response.data.code == 1) {
            this.$notify({
              title: response.data.msg,
              type: "success",
              offset: 40,
              duration: 2000,
            });
            // 关闭窗口
            this.dialogVisible = false;
            // 刷新列表
            this.queryRotation();
            
          } else {
            this.$notify.info({
              title: "保存失败：" + response.data.msg,
              type: "warning",
              offset: 40,
              duration: 2000,
            });

          }
        });
      }
      
    });
  },
  editInfo() {
    this.$refs["ruleForm"].validate((valid) => {
      if (valid) {
        if(this.ruleForm.image.indexOf(this.serverUrl) != -1){
          this.ruleForm.image = this.ruleForm.image.replaceAll(this.serverUrl,'');
        }

        // 调用接口保存
        rotationApi.editRotation(this.ruleForm).then((response) => {
          if (response.data.code == 1) {
            this.$notify({
              title: response.data.msg,
              type: "success",
              offset: 40,
              duration: 2000,
            });
            // 关闭窗口
            this.dialogVisible = false;
            // 重置表单
            this.$refs['ruleForm'].resetFields();
            // 刷新列表
            this.queryRotation();
          } else {
            this.$notify.info({
              title: "修改失败：" + response.data.msg,
              type: "warning",
              offset: 40,
              duration: 2000,
            });

          }
        });
      }
    });
  },
  // 弹窗关闭事件
  handleClose() {
    this.ruleForm = {};
  },
},
};
</script>

<style scoped>
  .is-penultimate > .el-tree-node__content {
    color: red;
  }

    /* 上传 */
    .el-upload{
      display: block; 
    }
 .avatar-uploader .el-upload {
    width: 210px;
    height: 120px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 450px;
    height: 148px;
    line-height: 148px;
    text-align: center;
    border: 1px solid #e9e9eb;
  }
  .avatar {
    height: 130px;
    /* display: block; */
  }
  .preview{
    overflow: hidden;
  }
  .preview img{
    max-width: 1200px;
  }
</style>